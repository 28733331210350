import {useGetUserStats} from "../../../services/react-query/panel";
import {useEffect, useState} from "react";


const useUserPieStats = () => {

    const { data } = useGetUserStats()
    const [active, setActive] = useState(null)
    const [verify, setVerify] = useState(null)
    const [stats, setStats] = useState(null)
    const [table, setTable] = useState(null)

    useEffect(() => {
        if (data) {
            const newActive = []
            newActive.push({ en: 'active', fa: 'فعال', value: data.userActive, color: '#819F82' })
            newActive.push({ en: 'inactive', fa: 'غیر فعال', value: data.userTotal - data.userActive, color: '#9F819E' })
            setActive(newActive)

            const newVerify = []
            newVerify.push({ en: 'verified', fa: 'تایید شده', value: data.userVerified , color: '#818F9F' })
            newVerify.push({ en: 'not-verified', fa: 'تایید نشده', value: (data.userTotal - data.userVerified) , color: '#9F9181' })
            setVerify(newVerify)

            const newStats = []
            newStats.push({ en: 'daily', fa: 'روزانه', value: data.userDay, color: '#9F9181' })
            newStats.push({ en: 'weekly', fa: 'هفتگی', value: data.userWeek, color: '#819F82' })
            newStats.push({ en: 'monthly', fa: 'ماهانه', value: data.userMonth, color: '#818F9F' })
            setStats(newStats)

            const newTable = []
            Object.keys(data).forEach(key => {
                const value = data[key]
                if (typeof value === 'number') {
                    newTable.push({ name: key, value })
                }
            })
            setTable(newTable)
        }
    }, [data])

    return {
        active,
        verify,
        stats,
        table
    }
}

export default useUserPieStats
