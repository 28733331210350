import {
	Background,
	CMargin,
	Flex,
	FadeAnimationContainer,
	DText,
} from '../../../styles/CommonStyles'
import { LoginWrapper, LoginLabel, LoginBody } from '../../../styles/main/login'
import Input from '../../common/input/Input'
import Text from '../../../core/utils/Text'
import { useEffect, useRef } from 'react'
import { RECAPTCHA_KEY } from '../../../core/constants/common'
import ActionButton from '../../common/buttons/ActionButton'
import ReCAPTCHA from 'react-google-recaptcha'
import { useMainContext } from '../../../core/contexts/main'
import { AnimatePresence } from 'framer-motion'
import { fadeVariants } from '../../../core/utils/theme'
import Tooltip from '../../common/utils/Tooltip'
import useLogin, { INPUT_TYPES } from '../../../core/hooks/main/login/useLogin'
import useEnterFunction from '../../../core/hooks/common/useEnterFunction'

const LoginView = () => {
	const { theme, lang } = useMainContext()
	const captchaRef = useRef()

	const {
		step,
		inputData,
		inputStatus,
		loading,
		timer,
		validAction,
		onInputValueChange,
		onSubmitClicked,
		onCaptchaStatusChange,
		onResendClicked,
	} = useLogin()

	useEnterFunction(onSubmitClicked)

	return (
		<LoginWrapper>
			<LoginBody>
				<Background bg='primaryBg'>
					<Flex fw>
						<img src={require('../../../assets/images/logo.png')} alt={' '} width='200px' />
					</Flex>
					<CMargin margin='10px' />
					<LoginLabel>
						<Text tid={'phone-email-label'} />
					</LoginLabel>
					<Input
						label='phone-email'
						height='52px'
						direction='ltr'
						value={inputData.username}
						valid={inputStatus.username}
						onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.USERNAME)}
						error={inputData.username && 'invalid-mobile-email'}
						disabled={step !== 1}
						autoFocus={step === 1}
					/>

					<AnimatePresence exitBeforeEnter>
						{step >= 2 && (
							<FadeAnimationContainer variants={fadeVariants} initial='out' animate='in' exit='out'>
								<CMargin margin='10px' />
								<Flex fw justify='space-between'>
									<LoginLabel>
										<Text tid={'verify-code'} />
									</LoginLabel>

									{step === 2 && (
										<Tooltip content={timer > 0 ? 'resend-tooltip' : 'resend-code-tooltip'}>
											<DText
												onClick={onResendClicked}
												style={{ cursor: 'pointer', position: 'relative' }}
												primary
											>
												{timer > 0 ? timer : <Text tid={'resend'} />}
											</DText>
										</Tooltip>
									)}
								</Flex>
								<Input
									label='code'
									height='52px'
									direction='ltr'
									value={inputData.code}
									valid={inputStatus.code}
									onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.CODE)}
									error={inputData.code && 'invalid-verify-code'}
									disabled={step !== 2}
									autoFocus={step === 2}
									maxLength={6}
									code
								/>
							</FadeAnimationContainer>
						)}
					</AnimatePresence>

					<AnimatePresence exitBeforeEnter>
						{step === 3 && (
							<FadeAnimationContainer variants={fadeVariants} initial='out' animate='in' exit='out'>
								<CMargin margin='10px' />
								<LoginLabel>
									<Text tid={'verify-google-code'} />
								</LoginLabel>
								<Input
									label='code'
									height='52px'
									direction='ltr'
									value={inputData.google}
									valid={inputStatus.google}
									onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.GOOGLE_AUTH)}
									error={inputData.google && 'invalid-verify-code'}
									disabled={step !== 3}
									autoFocus={step === 3}
									maxLength={6}
									code
								/>
							</FadeAnimationContainer>
						)}
					</AnimatePresence>

					{/*<CMargin margin='10px' />*/}
					{/*<Flex fw>*/}
					{/*    <ReCAPTCHA*/}
					{/*        key={theme}*/}
					{/*        sitekey={RECAPTCHA_KEY}*/}
					{/*        onExpired={() => onCaptchaStatusChange(false)}*/}
					{/*        onErrored={() => onCaptchaStatusChange(false)}*/}
					{/*        onChange={() => onCaptchaStatusChange(true)}*/}
					{/*        theme={theme}*/}
					{/*        hl={lang}*/}
					{/*        ref={captchaRef}*/}
					{/*    />*/}
					{/*</Flex>*/}

					<CMargin margin='20px' />
					<ActionButton active={validAction} onClick={onSubmitClicked} loading={loading}>
						<Text tid='submit' />
					</ActionButton>
				</Background>
			</LoginBody>
		</LoginWrapper>
	)
}

export default LoginView
