import TableLayout from "../../../../layout/main/TableLayout";
import { thirdBalanceBinanceAttributes } from "../../../../../core/constants/headers";
import { CFlex, Column, Flex, Row } from "../../../../../styles/CommonStyles";
import { SOCKET_URL } from "../../../../../core/constants/urls";


const BinanceTable = ({ data }) => {

    const { headers, cs } = thirdBalanceBinanceAttributes

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {data?.data?.data?.map((item, idx) => {

                return (
                    <Row cs={cs} key={item.asset} index={idx}>
                        <Column>{idx + 1}</Column>
                        <Column>
                            <Flex style={{ gap: "8px" }}>
                                <img
                                    src={SOCKET_URL + `assets/icon/${item.asset.toLowerCase()}.png`}
                                    width={32}
                                    height={32}
                                    alt={item.asset}
                                />
                                <span>{item.asset}</span>
                            </Flex>
                        </Column>
                        <Column>
                            {item.btcValuation}
                        </Column>
                        <Column>
                            {item.free}
                        </Column>
                        <Column>
                            {item.freeze}
                        </Column>
                        <Column>
                            {item.ipoable}
                        </Column>
                        <Column>
                            {item.locked}
                        </Column>
                        <Column>
                            {item.withdrawing}
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default BinanceTable
