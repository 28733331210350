import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import useCartable from "../../../../core/hooks/main/cartable/useCartable";
import RTableLayout from "../../layout/RTableLayout";
import {CFlex, CMargin, DText, Flex, Row} from "../../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../../core/constants/urls";
import Operation from "../../../common/utils/Operation";
import {balanceSignatureOperations} from "../../../../core/constants/operation";
import Text from "../../../../core/utils/Text";
import {TradeAmount, TradeTypeBadge} from "../../../../styles/main/orders";
import {formatNumber} from "../../../../core/utils/common";
import {MiniLineBreak, RespIndex} from "../../../../styles/main/MainCommonStyles";
import UserLink from "../../../common/utils/UserLink";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import ModalLayout from "../../../layout/main/ModalLayout";
import BalanceSignatureActionModal from "../../../modals/BalanceSignatureActionModal";
import BasicModal from "../../../modals/BasicModal";


const RCartableTable = ({ data }) => {

    const {data: multiSignatures} = data
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked,
        onDeleteClicked,
        actionModal,
        closeActionModal,
        deleteModal,
        closeDeleteModal,
        onSubmitDelete,
        deleteLoading
    } = useCartable()

    return (
        <>
            <RTableLayout
                data={data}
            >
                {multiSignatures?.data?.map((item, idx) => (
                    <Row
                        cs={'100%'}
                        index={idx}
                        key={item._id}
                    >
                        <Flex justify={'space-between'}>
                            <Flex justify={'flex-start'}>
                                <img
                                    src={
                                        item.params.currency === 'irt' ?
                                            require('../../../../assets/images/tooman.png')
                                            :
                                            SOCKET_URL + `assets/icon/${item.params.currency}.png`
                                    }
                                    width={'32px'}
                                    alt={' '}
                                />
                                <DText main style={{ margin: '0 8px' }}>
                                    {item.params.currency?.toUpperCase()}
                                </DText>
                            </Flex>
                            <Flex justify={'flex-end'}>
                                <Operation
                                    options={hasWriteAccess ? balanceSignatureOperations: []}
                                    onOptionClicked={(idx) => onOptionClicked(idx, item)}
                                    onDeleteClicked={() => onDeleteClicked(item)}
                                    onDetailsClicked={() => onDetailsClicked(item)}
                                    hasWriteAccess={item.status === 'pending'}
                                    hasDelete={item.status === 'pending' && !hasWriteAccess}
                                    deleteTooltip={'delete-cartable'}
                                    hasDetails
                                />
                            </Flex>
                        </Flex>

                        <CMargin margin={'8px'} />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='askBy' /> :
                            </DText>
                            <UserLink
                                _id={item.askBy}
                                name={item.askByUser?.email}
                            />
                        </Flex>
                        <MiniLineBreak />

                        <CFlex justify='space-between'>
                            <DText main fontSize='s' style={{width: '100%', marginBottom: '0.5rem'}}>
                                <Text tid='ask' /> :
                            </DText>
                            <DText fontSize='ss' main style={{width: '100%'}}>
                                {!!item.note &&
                                item.note
                            }
                            </DText>

                        </CFlex>
                        <MiniLineBreak />

                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'volume'} /> :
                            </DText>
                            <DText fontSize={'s'} primary>
                                {formatNumber(item.params.volume)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />

                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'action'} /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(item.params.action.toLowerCase())}>
                                <Text tid={item.params.action.toLowerCase()} />
                            </TradeTypeBadge>
                        </Flex>
                        <MiniLineBreak />

                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'status'} /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                                <Text tid={item.status} />
                            </TradeTypeBadge>
                        </Flex>
                        <CMargin margin={'6px'} />
                        <RespIndex>
                            {idx + 1}
                        </RespIndex>
                    </Row>
                ))}
            </RTableLayout>
            <ModalLayout
                width={'580px'}
                open={actionModal.open}
                onClose={closeActionModal}
            >
                <BalanceSignatureActionModal
                    data={actionModal}
                    onClose={closeActionModal}
                />
            </ModalLayout>
            <ModalLayout
                width={'580px'}
                open={deleteModal.open}
                onClose={closeDeleteModal}
            >
                <BasicModal
                    head={'delete-signature'}
                    onClose={closeDeleteModal}
                    onSubmit={onSubmitDelete}
                    loading={deleteLoading}
                />
            </ModalLayout>
        </>
    )
}

export default RCartableTable
