import {Background, CFlex, CMargin, DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import Input from "../../../common/input/Input";
import UpdateCoinTag from "../otc/elements/UpdateCoinTag";
import ActionButton from "../../../common/buttons/ActionButton";
import {useAvailableCoins} from "../../../../core/services/react-query/setting";
import {useEffect, useMemo, useState} from "react";
import {deepCopy} from "../../../../core/utils/common";
import {useQueryContext} from "../../../../core/contexts/query";
import {useAddCategory, useUpdateCategory} from "../../../../core/services/react-query/setting/coin-list";

const initialCategory = {
    name: '',
    icon: '',
    symbols: []
}

const CoinCategoryModal = ({ onSuccess, type = 'add', _category }) => {

    const { data: availableCoins } = useAvailableCoins()
    const { mutate: addCategory, isLoading: createCategoryLoading } = useAddCategory(onSuccess)
    const { mutate: updateCategory, isLoading: updateCategoryLoading } = useUpdateCategory(onSuccess)

    const [category, setCategory] = useState(initialCategory)
    useEffect(() => {
        if (!!_category) setCategory(_category)
    }, [_category])

    const onInputChange = (v, type) => {
        setCategory(state => ({...state, [type]: v}))
    }

    const onCoinStateChange = (coin, isActive) => {
        setCategory(state => {
            const newState = deepCopy(state)
            if (isActive) newState.symbols = newState.symbols.filter(d => d !== coin)
            else newState.symbols.push(coin)
            return newState
        })
    }

    const validAction = useMemo(() => {
        return !!category.name && !!category.icon && category.symbols.length
    }, [category])

    const { setToast } = useQueryContext()
    const onAction = () => {
        if (!validAction) setToast({message: 'fill-inputs-error', isError: true, show: true})
        else {
            if (type === 'add') addCategory(category)
            else updateCategory({ id: category?._id, payload: category })
        }
    }

    return (
        <CFlex fw>
            <DText main fontSize={'b'}>
                <Text tid={`${type}-category`} />
            </DText>
            <CMargin margin={'8px'} />
            <Background bg={'mainBg'}>
                <Input
                    value={category.name}
                    onInputChange={v => onInputChange(v, 'name')}
                    label={'name'}
                />
            </Background>
            <Background bg={'mainBg'}>
                <Input
                    value={category.icon}
                    onInputChange={v => onInputChange(v, 'icon')}
                    label={'icon'}
                />
            </Background>
            <DText main>
                <Text tid={'symbols'} />
            </DText>
            <CMargin margin={'2px'} />
            <Flex fw height={'400px'} style={{overflowY: 'scroll'}} wrap justify={'flex-start'}>
                {availableCoins?.map(coin => (
                    <UpdateCoinTag
                        coin={coin.id}
                        activeCoins={category.symbols}
                        onCoinStateChange={onCoinStateChange}
                    />
                ))}
            </Flex>
            <CMargin margin={'6px'} />
            <ActionButton active={validAction} height={'42px'} onClick={onAction} loading={createCategoryLoading || updateCategoryLoading}>
                <Text tid={'submit'} />
            </ActionButton>
        </CFlex>
    )
}

export default CoinCategoryModal
