import { binxBalanceAttributes } from "../../../../../core/constants/headers"
import { CFlex, Column, Flex, Row } from "../../../../../styles/CommonStyles";
import TableLayout from "../../../../layout/main/TableLayout"
import { SOCKET_URL } from "../../../../../core/constants/urls";


const BingX = ({ data }) => {

    const { headers, cs } = binxBalanceAttributes
    const innerData = data?.data?.data?.balances
    console.log({ innerData, data })


    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={{ data: { data: innerData || [] }, loading: data.loading }}
        >
            {data?.data?.data?.balances?.map((item, idx) => {

                return (
                    <Row cs={cs} key={item.asset} index={idx}>
                        <Column>{idx + 1}</Column>
                        <Column>
                            <Flex style={{ gap: "8px" }}>
                                <img
                                    src={SOCKET_URL + `assets/icon/${item.asset.toLowerCase()}.png`}
                                    width={32}
                                    height={32}
                                    alt={item.asset}
                                />
                                <span>{item.asset}</span>
                            </Flex>
                        </Column>
                        <Column>
                            {item.free}
                        </Column>
                        <Column>
                            {item.blocked}
                        </Column>

                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default BingX