import { RobotSettingCard } from "../../../../../styles/main/setting";
import { Background, CFlex, CMargin, DText } from "../../../../../styles/CommonStyles";
import Input from "../../../../common/input/Input";
import Text from "../../../../../core/utils/Text";


const RobotOrderBoxUpdate = ({
    market,
    type,
    transaction,
    onDetailsChange,
    index
}) => {

    const onInputValueChange = (value, key) => {
        onDetailsChange(value, 'market', index, {
            transaction,
            type,
            key
        })
    }

    const HAS_MIN_PRICE_FACTOR = type === 'order' || (type === 'trade' && transaction === 'sell')
    const HAS_MAX_PRICE_FACTOR = type === 'order' || (type === 'trade' && transaction === 'buy')
    const HAS_SKIP_ON_MINUTE = type === 'trade'
    const HAS_DELETE_ON_MINUTE = type === 'order'

    return (
        <RobotSettingCard>
            <CFlex fw>
                <Background bg={'primaryBg'}>
                    <DText color={transaction === 'buy' ? '#37b88b' : '#f6465d'}>
                        <Text tid={transaction} />
                    </DText>
                    <CMargin margin={'4px'} />
                    <Input
                        value={market[type][transaction].minAmount}
                        onInputChange={(v) => onInputValueChange(v, 'minAmount')}
                        label={'min-amount'}
                        currency={market.coin ? market.coin : 'coin'}
                        number
                    />
                    <Input
                        value={market[type][transaction].maxAmount}
                        onInputChange={(v) => onInputValueChange(v, 'maxAmount')}
                        label={'max-amount'}
                        currency={market.coin ? market.coin : 'coin'}
                        number
                    />
                    {HAS_MIN_PRICE_FACTOR &&
                        <>
                            <Input
                                value={market[type][transaction].minLowerPriceFactor}
                                onInputChange={(v) => onInputValueChange(v, 'minLowerPriceFactor')}
                                label={'minLowerPriceFactor'}
                                number
                            />
                            <Input
                                value={market[type][transaction].minUpperPriceFactor}
                                onInputChange={(v) => onInputValueChange(v, 'minUpperPriceFactor')}
                                label={'minUpperPriceFactor'}
                                number
                            />
                        </>
                    }

                    {HAS_MAX_PRICE_FACTOR &&
                        <>
                            <Input
                                value={market[type][transaction].maxLowerPriceFactor}
                                onInputChange={(v) => onInputValueChange(v, 'maxLowerPriceFactor')}
                                label={'maxLowerPriceFactor'}
                                number
                            />
                            <Input
                                value={market[type][transaction].maxUpperPriceFactor}
                                onInputChange={(v) => onInputValueChange(v, 'maxUpperPriceFactor')}
                                label={'maxUpperPriceFactor'}
                                number
                            />
                        </>

                    }

                    {HAS_SKIP_ON_MINUTE &&
                        <Input
                            value={market[type][transaction].skipOnMinutes}
                            onInputChange={(v) => onInputValueChange(v, 'skipOnMinutes')}
                            label={'skip-on-minutes'}
                            number
                        />
                    }

                    {HAS_DELETE_ON_MINUTE &&
                        <Input
                            value={market[type][transaction].deleteOnMinute}
                            onInputChange={(v) => onInputValueChange(v, 'deleteOnMinute')}
                            label={'delete-on-minute'}
                            number
                        />
                    }

                    <Input
                        value={market[type][transaction].skipOnCount}
                        onInputChange={(v) => onInputValueChange(v, 'skipOnCount')}
                        label={'skip-on-count'}
                        number
                    />
                </Background>
            </CFlex>
        </RobotSettingCard>
    )
}

export default RobotOrderBoxUpdate
