import { DText, Flex } from '../../../styles/CommonStyles'
import { DownloadIcon } from '../../../styles/main/setting'
import Text from '../../../core/utils/Text'
import styled from 'styled-components'
import { BarLoader } from 'react-spinners'

const DownloadBtn = (props) => {
	return (
		<Wrapper {...props}>
			<Flex fw fh>
				{props.loading ? (
					<BarLoader color={'#42958D'} width={'52px'} />
				) : (
					<>
						<DownloadIcon size={20} />
						<DText style={{ margin: '0 5px' }} fontSize={'ss'} main>
							<Text tid={'add-to-download-list'} />
						</DText>
					</>
				)}
			</Flex>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	height: 38px;
	margin: 4px;
	transition: all 0.2s;
	cursor: pointer;
	border-radius: 4px;
	box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
	width: 152px;
	padding: 0 12px;
	background-color: ${(props) => props.theme.secondaryBg};
	border: 1px solid ${(props) => props.theme.color}10;
`

export default DownloadBtn
